import React, { useState, useEffect } from "react";

// style //
import "./AddDocuments.css";
import Form from "react-bootstrap/Form";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Image //
import { images } from "../../assets/index";

// Components  //
import {
  FormInput,
  ButtonComp,
  PageHeading,
} from "../../common/components/index";
import { FileUploader } from "react-drag-drop-files";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// API's //
import { getSiteProjectApi } from "../../api/siteProjectApi";
import { addDocumentApi } from "../../api/inductionAndDocumentation"

// Actions //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

const AddDocuments = () => {
  const fileTypes = ["PDF"];

  // Hooks //
  const [formData, setFormData] = useState({
    name: "",
    document: "",
  });
  const navigate = useNavigate();
  const [siteList, setSiteList] = useState([])
  const [selectedSite, setSelectedSite] = useState({})
  const [respMessage, setRespMessage] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const { userToken } = useSelector((state) => state.user)
  const [error, setError] = useState('')

  const dispatch = useDispatch();

  // UseEffect //
  useEffect(() => {
    getSites();
  }, []);

  // Handlers //
  const handleChange = (file) => {
    setFormData({
      ...formData,
      document: file,
    });
  };

  const addDocumentHendler = async () => {
    try {
      if (
        !formData.name ||
        !formData.document ||
        !selectedSite.id
      ) {
        alert("!!Please fill all the fields!!");
        return;
      }
      setError('')
      dispatch(requestSent());

      const data = await addDocumentApi(userToken, {
        ...formData,
        site_id: selectedSite.id,
      });
      setRespMessage(data.message)
      setFormData({
        name: "",
        document: "",
      })
      setSelectedSite({})
      dispatch(responseRecived())
      setModalShow(true)
    } catch (error) {
      if (error?.responseCode === 535 || error?.error_message?.startsWith('{"multicast_id":')) {
        setFormData({
          name: "",
          document: "",
        })
        setSelectedSite({})
        setRespMessage("Site document uploaded successfully")
        setModalShow(true)
        return;
      }
      // dispatch(receivedError(error));
      setError(error)
      dispatch(responseRecived());
    }
  };

  const modalHendler = () => {
    dispatch(receivedError(null));
    setModalShow(false)
    navigate("/induction")
  };

  const getSites = async () => {
    try {
      dispatch(requestSent());
      const data = await getSiteProjectApi(userToken, {
        page: 1,
        limit: 20,
      });
      setSiteList(data.data.rows)
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  }

  // Modal //
  const CustomModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.success} alt="" />
            <h2>Document Added</h2>
            <p>{respMessage}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={modalHendler}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <CustomModal />
      <PageHeading title={"Induction & Documents : Add Documents"} />
      <div id="add-documents" className="right-sec_cont">
        <div>
          <h2>Document Details</h2>
          <FormInput
            width={"30vw"}
            lable="Document Name"
            placeholder={"Enter Document Name"}
            onchange={(value) =>
              setFormData({
                ...formData,
                name: value,
              })
            }
            value={formData.name}
            type={"text"}
            error={error?.error_key === "name" ? true : false}
          />
          <div className="select_inp form_input_cont">
            <p>Site Ref</p>
            <Form.Select
              className="input"
              onChange={(e) => {
                if (!e.target.value) {
                  setSelectedSite({
                    name: '',
                    id: null,
                    reference: ''
                  })
                } else {
                  setSelectedSite({
                    name: siteList[e.target.selectedIndex - 1].name,
                    id: siteList[e.target.selectedIndex - 1].id,
                    reference: siteList[e.target.selectedIndex - 1].reference
                  })
                }
              }
              }
              value={selectedSite.reference + " " + selectedSite.name}
            >
              <option></option>
              {
                siteList.map(sl => <option id={sl.id}>{sl.reference + " " + sl.name}</option>)
              }
            </Form.Select>
          </div>
          <div className="drag_and_drop_cont form_input_cont">
            <p>Upload Document</p>
            <div className="drop-here">
              <div className="txt_cont">
                <img src={images.uploadingCloud} alt="" />
                {formData?.document ? (
                  <p className="browse">{formData?.document.name}</p>
                ) : (
                  <>
                    <p>Drag and drop here</p>
                    <p>or</p>
                    <p className="browse">browse</p>
                    <p className="file_type">
                      <b>File Type :</b>
                      {fileTypes.map((item) => (
                        <span>{item}</span>
                      ))}
                    </p>
                  </>
                )}
              </div>
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
            </div>
          </div>
          {error ? <p className="err_message">*{error?.error_message}</p> : ''}
          <ButtonComp
            onClick={addDocumentHendler}
            title={"Add Document"}
            width={"30vw"}
          />
        </div>
      </div>
    </>
  );
};

export default AddDocuments;
