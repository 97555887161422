import moment from "moment";

export const convertDateFormat = (inputDate, currentFormat, expectedFormat) => {
    const date = moment(inputDate, currentFormat)
    return date.format(expectedFormat);
}
 
export function tillNextWeekFriday(date = null, isTime = true) {
    let format = 'YYYY-MM-DD H:mm:ss';
    let currentMoment = date ? moment(date) : moment();

    if (!isTime) { format = 'YYYY-MM-DD' }

    // Ensure the moment object is valid before proceeding
    if (!moment(date).isValid()) {
        currentMoment = moment(); // Default to the current date if the provided date is invalid
    } else {
        currentMoment = moment(date);
    }

    // Check if the current day is Sunday or Monday
    if (currentMoment.day() === 0 || currentMoment.day() === 1) {
        // If it's Sunday or Monday, get the upcoming Friday
        return currentMoment.day(5).endOf("day").format(format);
    }

    return currentMoment.add(1, 'weeks').day(5).endOf("day").format(format);
}