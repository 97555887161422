import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { receivedError, requestSent, responseRecived } from '../../../redux/actions/utilsActions';
import { approveManualTimesheet } from '../../../api/timeSheetApi';
import { icons } from '../../../assets';

const ManualTimesheetRow = ({ data, getTimeSheetDetails, onChangeManualTimesheet }) => {
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.user);

    const [rowData, setRowData] = useState(data);

    useEffect(() => {
        setRowData(data)
    }, [data.remark, data.adjusted_amount])

    const isTImesheetApproved = data.status === 'APPROVED';
    const workUnit = data.SoleTrader.WorkUnit;
    const dailyPayRate = data.SoleTrader.Contractors[0].contractors_sole_traders.daily_pay_rate;
    const dayRate = workUnit === 'DAYS' ? dailyPayRate : '-';
    const hourlyRate = +(workUnit === 'HOURS' ? (dailyPayRate || 0) : dailyPayRate / 9);
    
    const onChnageManualTimesheets = e => {
        let { value, name } = e.target;
        setRowData(p => {
            let dataToUpdate = {
                ...p,
                is_manual: true,
                [name]: value
            };
            
            onChangeManualTimesheet(dataToUpdate)
            
            delete dataToUpdate.type;

            return dataToUpdate;
        })
    };

    const approveTimesheet = async () => {
        try {
            dispatch(requestSent());
            await approveManualTimesheet(userToken, {
                id: data.id,
                adjusted_amount: rowData.adjusted_amount,
                remark: rowData.remark,
                status: data.status === 'APPROVED' ? 'PENDING' : 'APPROVED'
            })
            dispatch(responseRecived());
            getTimeSheetDetails()
        } catch (error) {
            dispatch(receivedError(error));
            console.log("TimeSheet Api Error :::", error);
        }
    };

    return (
        <tr key={data.id}>
            <td style={{ minWidth: '10rem' }}>
                <button className="approve_timesheet_btn" onClick={approveTimesheet}>
                    <img src={icons.Check} alt="approve" width={'10px'} />
                    {isTImesheetApproved ? 'APPROVED' : 'APPROVE'}
                </button>
            </td>
            <td>{data.SoleTrader.SoleTraderProfile.firstname} {data.SoleTrader.SoleTraderProfile.surname}</td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>0.00</td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>0.00</td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>0.00</td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>0.00</td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>0.00</td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>0.00</td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>0.00</td>
            <td>-</td>
            <td>-</td>
            <td>{`£${hourlyRate.toFixed(2)}`}</td>
            <td>{workUnit === 'HOURS' ? '-' : dayRate}</td>
            <td>£0.00</td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>
                <input type='number' name="adjusted_amount" value={rowData.adjusted_amount} onChange={onChnageManualTimesheets} disabled={isTImesheetApproved} />
            </td>
            <td className="bordered" style={{ backgroundColor: isTImesheetApproved ? '#EB742E' : 'white' }}>
                <input type='text' name="remark" value={rowData.remark} onChange={onChnageManualTimesheets} disabled={isTImesheetApproved} />
            </td>

            <td>£{rowData.adjusted_amount}</td>
        </tr>
    )
}

export default ManualTimesheetRow
