import React, { useState, useRef, useEffect } from "react";

// Style //
import "./CustomDropDown.css";

// Components //
import { TableRowLoader } from "../../components/index";

import { useSelector } from "react-redux";

const CustomSelectMultipleDropdown = ({ lable, options, onSelect, error, selectedValue = [], openTop, styles }) => {

  // Hooks //
  const dropdownRef = useRef();
  const [isChecked, setIsChecked] = useState(false);
  // const [selectedItem, setSelectedItem] = useState("");
  const { loading } = useSelector((state) => state.utils);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsChecked(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  //   Handlers //
  const optionsHendler = (item) => {
    setIsChecked(false);
    onSelect(item)
  };

  return (
    <div
      className={`${isChecked ? "open" : ""} custom_dropdown`}
      style={styles}
      ref={dropdownRef}
    >
      <div className="sec-center">
        {
          error ? (<p className="red-txt">*{lable}</p>) : (<p>{lable}</p>)
        }
        <div
          className={`dropdown-btn ${error ? "red-border" : ''}`}
          onClick={() => setIsChecked(!isChecked)}
        >
          <span>{selectedValue?.length > 0 ? selectedValue?.map((selectedItem) => (
            `${selectedItem.name},`
          )) : lable}</span>
          <i className="uil  fa-solid fa-chevron-down"></i>
        </div>
        <div className={`section-dropdown ${openTop ? "openTop" : ''}`}>
          <div className="link_cont">
            {
              loading ? <TableRowLoader limit={5} height={30} mt={"mt-2"} /> : (
                <>
                  {options?.map((option, index) => {
                    const isSelected = (selectedValue.length && selectedValue.some(sv => sv.name === option.name)); 
                    return <div className="options" key={index}>
                      {`${option.name}`}
                      <div onClick={() => optionsHendler(option)} className={`${isSelected && 'selected_option'}`}>
                        {isSelected ? 'Selected' : 'Select'}
                      </div>
                    </div>
                  })}
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSelectMultipleDropdown;
