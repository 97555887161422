import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";

// Images //
import { images } from "../../assets/index";

// Component //
import {
  FormInput,
  PageHeading,
  ButtonComp,
  Loader,
  CustomDropDown,
} from "../../common/components/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Actions //
import {
  receivedError,
  responseRecived,
  requestSent,
} from "../../redux/actions/utilsActions";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getModuleApi } from "../../api/sideBarApi";
import CustomSelectMultipleDropdown from "../../common/components/custom-dropdown/CustomSelectMultipleDropdown";
import { editUsersApi, getSingleUserApi } from "../../api/users";

const EditUser = () => {
  // Hooks //
  const { userToken, user } = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    address: "",
    phone_number: "",
    signatory_on_account: "",
    module: [],
    user_id
  });
  const [respMessage, setRespMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [modules, setModules] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [onPageLoader, setOnPageLoader] = useState(false);

  useEffect(() => {
    getModules()
    getCurentUser()
  }, [])

  const getModules = async () => {
    try {
      dispatch(requestSent());
      const data = await getModuleApi(userToken);
      setModules(data.contractor_modules);
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error))
      dispatch(responseRecived());
    }
  }

  const getCurentUser = async () => {
    try {
      dispatch(requestSent());
      const { data } = await getSingleUserApi(userToken, user_id);
      setFormData(prev => ({
        ...prev,
        fullname: data.fullname,
        email: data.email,
        phone_number: data.phone_number,
        signatory_on_account: data.signatory_on_account,
        address: data.address
      }))
      data.contractor_modules.forEach((item) => {
        handleSelectFeature(item.module_type)
      })
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  }

  // Handler //
  const edituserHendler = async () => {
    try {
      if (
        !formData.fullname ||
        !formData.address ||
        !formData.email ||
        !formData.phone_number ||
        !formData.signatory_on_account ||
        !formData.module.length ||
        !formData.user_id
      ) {
        alert("!!Please fill all the fields!!");
        return;
      }
      setError("");
      dispatch(requestSent());
      setOnPageLoader(true);
      const data = await editUsersApi(userToken, formData);
      setOnPageLoader(false);
      setRespMessage(data.message);
      dispatch(responseRecived());
      setModalShow(true);
    } catch (error) {
      console.log("Add User Page error::", error);
      dispatch(receivedError(error));
      setError(error);
      setOnPageLoader(false);
      dispatch(responseRecived());
    }
  };

  const modalHendler = () => {
    setModalShow(false);
    navigate("/users");
  };

  const handleSelectFeature = (e) => {
    const newValue = e;
    if (selectedValues.indexOf(newValue) === -1 && !(formData.module.some(m=>m === e.id))) {
        setSelectedValues(prev=> ([...prev, newValue]));
        setFormData(prev => ({
          ...prev,
          module: [
            ...prev.module,
            Number(newValue.id),
          ],
        }));

    }
  };

  // Modal //
  const CustomModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.success} alt="" />
            <h2>User Updated</h2>
            <p>{respMessage}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={modalHendler}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (onPageLoader) {
    return <Loader />;
  }

  return (
    <>
      <CustomModal />
      <PageHeading title={"Add A New User"} />
      <div id="add-subcontractor" className="right-sec_cont">
        <div>
          <h2>User Details</h2>
          <FormInput
            width={"30vw"}
            lable="Name"
            placeholder={"Enter User Name"}
            onchange={(value) =>
              setFormData({
                ...formData,
                fullname: value,
              })
            }
            value={formData.fullname}
            type={"text"}
            error={error?.error_key === "fullname" ? true : false}
          />
          <FormInput
            width={"30vw"}
            lable="Email"
            placeholder={"Enter Email"}
            onchange={(value) =>
              setFormData({
                ...formData,
                email: value,
              })
            }
            value={formData.email}
            type={"text"}
            error={error?.error_key === "email" ? true : false}
          />

          <FormInput
            width={"30vw"}
            lable="Mobile Number"
            placeholder={"Enter Mobile No."}
            onchange={(value) => {
              setFormData({
                ...formData,
                phone_number: value,
              });
            }}
            value={formData?.phone_number}
            type={"number"}
            error={error?.error_key === "phone_number" ? true : false}
          />

          <FormInput
            width={"30vw"}
            lable="Address"
            placeholder={"Enter Address"}
            onchange={(value) =>
              setFormData({
                ...formData,
                address: value,
              })
            }
            value={formData.address}
            type={"text"}
            error={error?.error_key === "address" ? true : false}
          />

          {modules.length && <CustomSelectMultipleDropdown
            lable={"Features-Permitted"}
            options={modules.map(m => m.module_type)}
            onSelect={handleSelectFeature}
            selectedValue={selectedValues}
          />}

          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              onChange={(e) => setFormData(prev => {
                return {
                  ...prev,
                  signatory_on_account: e.target.checked
                }
              })}
            />
            <label className="form-check-label" for="exampleCheck1">
              Signatory On Account
            </label>
          </div>

          {error ? <p className="err_message">*{error?.error_message}</p> : ""}
          <ButtonComp
            onClick={edituserHendler}
            title={"Edit User"}
            width={"30vw"}
          />
        </div>
      </div>
    </>
  );
};

export default EditUser;
