import React from "react";

// Style //
import "./TimeSheetDetail.css";

// Component //
import { PageHeading, Loader } from "../../common/components/index";
import Table from "react-bootstrap/Table";

// API's //
import {
  approveMasterTimeSheetApi,
  getTimesheetDetailsApi,
  downloadMeasuredWorksApi,
  approveSingleTimeSheetApi,
} from "../../api/timeSheetApi";

// Images //
import { icons } from "../../assets/index";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  useLocation,
} from "react-router-dom";
// Actions //
import {
  requestSent,
  responseRecived,
  receivedError,
} from "../../redux/actions/utilsActions";
import { Button, Modal } from "react-bootstrap";
import CustomSelectMultipleDropdown from "../../common/components/custom-dropdown/CustomSelectMultipleDropdown";
import NoTimesheetRow from "../../common/components/time-sheet-table/NoTimesheetRow";
import ManualTimesheetRow from "../../common/components/time-sheet-table/ManualTimesheetRow";

const TimeSheetDetail = () => {

  // Hooks //
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading } = useSelector((state) => state.utils);
  const { userToken, user } = useSelector((state) => state.user);

  const workPeriod = location.state && location.state.workPeriod;

  const [timeSheetDetails, setTimeSheetDetails] = useState([]);
  const [measuredWorks, setMeasuredWorks] = useState([]);
  const [manualTimesheets, setManualTimesheets] = useState([]);
  const [soleTradersWithNoTimesheet, setSoleTradersWithNoTimesheet] = useState([]);
  const [showApproveTimesheetModal, setShowApproveTimesheetModal] = useState(false);
  const [isTImesheetApproved, setIsTimesheetApproved] = useState(true);
  const [isTImesheetsSubmitted, setIsTimesheetSubmitted] = useState(true);
  const [changedTimesheets, setChangedTimesheets] = useState([]);
  const [numberOfSoleTraders, setNumberOfSoleTraders] = useState(0)
  const [showComments, setShowComments] = useState(0)
  const [comments, setComments] = useState([])
  const [adminCharge, setAdminCharge] = useState(0);
  const [soleTraders, setSoleTraders] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedSoleTraders, setSelectedSoleTraders] = useState([])
  const [selectedSites, setSelectedSites] = useState([])
  const initiaTotalData = {
    mondayHours: 0,
    tuesdayHours: 0,
    wednesdayHours: 0,
    thursdayHours: 0,
    fridayHours: 0,
    saturdayHours: 0,
    sundayHours: 0,
    totalHours: 0,
    hourlyRate: 0,
    dayRate: 0,
    totalPay: 0,
    manullay_deduction: 0,
    adjustedPay: 0,
  };

  const [totalData, setTotalData] = useState(initiaTotalData)

  useEffect(() => {
    getTimeSheetDetails();
  }, [selectedSites.length, selectedSoleTraders.length]);

  // API Calls //
  const getTimeSheetDetails = async () => {
    try {
      const shouldCalculateExtraData = !(selectedSites.length || selectedSoleTraders.length)

      dispatch(requestSent());
      const data = await getTimesheetDetailsApi(userToken, {
        workPeriod: workPeriod.split(' - ')[0],
        selectedSites: JSON.stringify(selectedSites.map(s => s.id)),
        selectedSoleTraders: JSON.stringify(selectedSoleTraders.map(s => s.id))
      });

      let sitesWorked = []

      shouldCalculateExtraData && setAdminCharge(data.companyInfo.admin_charge)

      if (data.manualTimesheets) {
        setManualTimesheets(data.manualTimesheets);
      }

      if (data.data) {
        setTimeSheetDetails(data.data)
        shouldCalculateExtraData && data.data.forEach(d => sitesWorked.push({ name: d.name, id: d.id }))
      }

      if (data.measuredWorks) {
        shouldCalculateExtraData && data.measuredWorks.forEach(msw => {
          if (!sitesWorked.some(sw => sw.id === msw.Site.id)) sitesWorked.push({ name: msw.Site.name, id: msw.site_id })
        })
        setMeasuredWorks(data.measuredWorks)
      }

      if (!data.measuredWorks && !data.data) {
        setIsTimesheetSubmitted(false);
        setIsTimesheetApproved(false)
      }

      if (data.soleTradersWithNoData) {
        setSoleTradersWithNoTimesheet(data.soleTradersWithNoData);
      }

      shouldCalculateExtraData && setSites(sitesWorked)
      calculateTotalAmount(data)

      // Site Details
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      console.log("TimeSheet Details Api Error :::", error);
      dispatch(responseRecived());
    }
  };

  const calculateTotalAmount = (data) => {
    const shouldCalculateExtraData = !(selectedSites.length || selectedSoleTraders.length)
    let noTimesheetDataTotal = initiaTotalData;
    let timesheetDataTotal = initiaTotalData;

    let soleTradersData = []

    let measuredWorksTotal = {
      totalPay: 0,
      manullay_deduction: 0,
      adjustedPay: 0,
    };

    let manualTimesheetsTotal = 0;

    if (data.soleTradersWithNoData) {
      noTimesheetDataTotal = data.soleTradersWithNoData.reduce((acc, val) => {
        const workUnit = val.WorkUnit;
        const soleTrader = val.SoleTraderProfile.firstname + " " + val.SoleTraderProfile.surname;

        !soleTradersData.some(s => s && s.id === val.id) && soleTradersData.push({ name: soleTrader, id: val.id })

        const hourlyRate = workUnit === 'HOURS' ?
          val.Contractors[0].contractors_sole_traders.daily_pay_rate || 0 : workUnit === 'DAYS' ?
            val.Contractors[0].contractors_sole_traders.daily_pay_rate / 9 : 0;

        const dayRate = workUnit === 'DAYS' ? val.Contractors[0].contractors_sole_traders.daily_pay_rate : 0;

        return {
          mondayHours: 0,
          tuesdayHours: 0,
          wednesdayHours: 0,
          thursdayHours: 0,
          fridayHours: 0,
          saturdayHours: 0,
          sundayHours: 0,
          totalHours: 0,
          hourlyRate: acc.hourlyRate + hourlyRate,
          dayRate: acc.dayRate + dayRate,
          totalPay: 0,
          manullay_deduction: 0,
          adjustedPay: 0,
        }
      }, initiaTotalData)
    }

    // Calculations for timesheet soletraders
    if (data.data) {
      let allTimesheets = [];

      data.data.forEach(d => {
        allTimesheets.push(...d.TimeSheets)
      })

      timesheetDataTotal = allTimesheets.reduce((acc, val) => {
        const soleTrader = val.SoleTrader.SoleTraderProfile.firstname + " " + val.SoleTrader.SoleTraderProfile.surname;

        !soleTradersData.some(s => s && s.id === val.SoleTrader.id) && soleTradersData.push({ name: soleTrader, id: val.SoleTrader.id });

        if (!val.is_freezed) {
          setIsTimesheetApproved(false)
        }
        const workUnit = val.SoleTrader.WorkUnit;
        const totalHours = +val.monday_working_hours + +val.tuesday_working_hours + +val.wednesday_working_hours + +val.thursday_working_hours + +val.friday_working_hours + +val.saturday_working_hours + +val.sunday_working_hours;

        const hourlyRate = workUnit === 'HOURS' ?
          val.current_daily_pay_rate || 0 : workUnit === 'DAYS' ?
            val.current_daily_pay_rate / 9 : 0;

        const dayRate = workUnit === 'DAYS' ? +val.current_daily_pay_rate : 0;
        const totalPay = +(totalHours * hourlyRate)

        return {
          mondayHours: acc.mondayHours + +val.monday_working_hours,
          tuesdayHours: acc.tuesdayHours + +val.tuesday_working_hours,
          wednesdayHours: acc.wednesdayHours + +val.wednesday_working_hours,
          thursdayHours: acc.thursdayHours + +val.thursday_working_hours,
          fridayHours: acc.fridayHours + +val.friday_working_hours,
          saturdayHours: acc.saturdayHours + +val.saturday_working_hours,
          sundayHours: acc.sundayHours + +val.sunday_working_hours,
          totalHours: acc.totalHours + totalHours,
          hourlyRate: acc.hourlyRate + +hourlyRate,
          dayRate: acc.dayRate + dayRate,
          totalPay: acc.totalPay + totalPay,
          manullay_deduction: acc.manullay_deduction + +val.manullay_deduction,
          adjustedPay: acc.adjustedPay + (totalPay + +val.manullay_deduction),
        }
      }, initiaTotalData)
    }

    if (data.manualTimesheets) {
      manualTimesheetsTotal = data.manualTimesheets.reduce((acc, val) => {
        const soleTrader = val.SoleTrader.SoleTraderProfile.firstname + " " + val.SoleTrader.SoleTraderProfile.surname;

        !soleTradersData.some(s => s && s.id === val.SoleTrader.id) && soleTradersData.push({ name: soleTrader, id: val.SoleTrader.id });

        if (!val.is_freezed) {
          setIsTimesheetApproved(false)
        }

        return acc + +val.adjusted_amount
      }, 0)
    }

    if (data.measuredWorks) {
      measuredWorksTotal = data.measuredWorks.reduce((acc, val) => {
        const soleTrader = val.SoleTrader.SoleTraderProfile.firstname + " " + val.SoleTrader.SoleTraderProfile.surname;

        !soleTradersData.some(s => s && s.id === val.SoleTrader.id) && soleTradersData.push({ name: soleTrader, id: val.SoleTrader.id });

        if (!val.is_freezed) {
          setIsTimesheetApproved(false)
        }

        let uptObj = {
          totalPay: acc.totalPay + +(val.adjusted_amount || val.gross_amount),
          manullay_deduction: acc.manullay_deduction + +val.manullay_deduction,
          adjustedPay: +acc.adjustedPay + ((val.adjusted_amount || val.gross_amount) + +val.manullay_deduction),
        }

        return {
          ...uptObj,
          adjustedPay: +uptObj.totalPay + +uptObj.manullay_deduction
        }
      }, { ...measuredWorksTotal });
    }

    const dataToUpload = {
      ...timesheetDataTotal,
      totalPay: +timesheetDataTotal.totalPay + +measuredWorksTotal.totalPay,
      hourlyRate: (timesheetDataTotal.hourlyRate + noTimesheetDataTotal.hourlyRate) / soleTradersData.length,
      dayRate: timesheetDataTotal.dayRate + noTimesheetDataTotal.dayRate,
      manullay_deduction: timesheetDataTotal.manullay_deduction + measuredWorksTotal.manullay_deduction + manualTimesheetsTotal,
      adjustedPay: +timesheetDataTotal.adjustedPay + +measuredWorksTotal.adjustedPay + manualTimesheetsTotal,
    }

    shouldCalculateExtraData && setSoleTraders(soleTradersData);
    shouldCalculateExtraData && setNumberOfSoleTraders(soleTradersData.length)

    setTotalData(dataToUpload)
  }

  const approveSingletimesheet = async (data, isApproved) => {
    try {
      dispatch(requestSent());
      await approveSingleTimeSheetApi(userToken, data, isApproved);
      await getTimeSheetDetails()
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      console.log("TimeSheet Api Error :::", error);
      dispatch(responseRecived());
    }
  }

  const approveMasterTimeSheetHandler = async () => {
    try {
      setShowApproveTimesheetModal(false)
      dispatch(requestSent());
      if (changedTimesheets.length) {
        await approveMasterTimeSheetApi(userToken, workPeriod.split(' - ')[0], changedTimesheets);
      } else {
        await approveMasterTimeSheetApi(userToken, workPeriod.split(' - ')[0]);
      }
      setIsTimesheetApproved(true)
      await getTimeSheetDetails()
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      console.log("TimeSheet Api Error :::", error);
      dispatch(responseRecived());
    }
  };

  const handleDownloadClaim = async (id) => {
    try {
      dispatch(requestSent());
      const data = await downloadMeasuredWorksApi(userToken, { working_period: workPeriod.split('-')[0].trim(), sole_trader_id: id });
      const fileurl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileurl;
      link.setAttribute('download', `timesheet.pdf`);
      document.body.appendChild(link);
      link.click();
      dispatch(responseRecived());
    } catch (error) {

    }
  }

  const onChnageInput = (e, isMeasuredWorks) => {
    let { value, name, dataset } = e.target;
    if (value.startsWith('£')) {
      value = value.slice(1)
    }

    if (isMeasuredWorks) {
      setMeasuredWorks(prev => {
        const updatedMeasuredWork = prev.map(ts => {
          if (ts.id === +dataset.timesheet) {
            let updatedData = {
              ...ts,
              [name]: value,
            };

            setChangedTimesheets(prev => {
              const updatedArray = prev;
              if (updatedArray.some(p => p.id === ts.id)) {
                const itemIndex = updatedArray.findIndex(p => p.id === ts.id);
                updatedArray[itemIndex] = updatedData
              } else {
                updatedArray.push(updatedData)
              }
              return updatedArray
            })

            return updatedData
          } else {
            return ts
          }
        });
        calculateTotalAmount({ data: timeSheetDetails, measuredWorks: updatedMeasuredWork, soleTradersWithNoData: soleTradersWithNoTimesheet, totalActiveSoleTraders: numberOfSoleTraders, manualTimesheets });
        return updatedMeasuredWork
      })
      return;
    }

    setTimeSheetDetails(prev => {
      const updatedData = prev.map(p => {
        if (p.id === +dataset.site) {
          const updatedTimesheet = p.TimeSheets.map(ts => {
            if (ts.id === +dataset.timesheet) {
              const updatedData = {
                ...ts,
                [name]: value
              }
              setChangedTimesheets(prev => {
                const updatedArray = prev;
                if (updatedArray.some(p => p.id === ts.id)) {
                  const itemIndex = updatedArray.findIndex(p => p.id === ts.id);
                  updatedArray[itemIndex] = updatedData
                } else {
                  updatedArray.push(updatedData)
                }
                return updatedArray
              })
              return updatedData
            } else {
              return ts
            }
          });

          return {
            ...p,
            TimeSheets: updatedTimesheet
          }

        } else {
          return p
        }
      });

      calculateTotalAmount({ data: updatedData, measuredWorks, soleTradersWithNoData: soleTradersWithNoTimesheet, totalActiveSoleTraders: numberOfSoleTraders, manualTimesheets });
      return updatedData;
    });
  }

  const onChangeManualTimesheet = (dataToUpdate) => {
    setChangedTimesheets(prev => {
      const updatedArray = prev;
      if (updatedArray.some(preArr => preArr.id === dataToUpdate.id && preArr.is_manual)) {
        const itemIndex = updatedArray.findIndex(pre => pre.id === dataToUpdate.id);
        updatedArray[itemIndex] = dataToUpdate
      } else {
        updatedArray.push(dataToUpdate)
      }
      return updatedArray
    });    
  }

  const handleSelectSoleTraders = (e) => {
    setSelectedSoleTraders(p => {
      if (p.some(d => d.name === e.name)) {
        const result = p.filter(f => f.name !== e.name)
        return result
      } else {
        const result = [...p, e];
        return result
      }
    })
  }

  const handleSelectSite = (e) => {
    setSelectedSites(p => {
      if (p.some(d => d.name === e.name)) {
        const result = p.filter(f => f.name !== e.name);
        return result
      } else {
        const result = [...p, e];
        return result
      }
    })
  }

  const resetFilters = () => {
    setSelectedSoleTraders([])
    setSelectedSites([])
  }

  if (loading) {
    return <Loader />;
  }

  const ApproveTimesheetModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showApproveTimesheetModal}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <h2>Are you sure you want to Approve all the Timesheets of the working period ?</h2>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn_style"
            variant="secondary"
            onClick={() => setShowApproveTimesheetModal(false)}
          >
            No
          </Button>
          <Button className="orange_btn btn_style" onClick={approveMasterTimeSheetHandler}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const CommentsModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showComments}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <h2>Comments</h2>
          </div>

          {
            comments.length !== 0 && comments.map(c => <p className="comment_row" key={c.day}><span>{c.day}</span><span>{c.comment}</span></p>)
          }
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn_style"
            variant="secondary"
            onClick={() => setShowComments(false)}
          >
            Hide
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <ApproveTimesheetModal />
      <CommentsModal />
      <PageHeading
        companyName={user.contractorData.Company.name}
        title={"Sub Contractor TimeSheet"}
        paragraph={`TimeSheet Period:-  ${workPeriod}`}
      />

      <div className="master_timesheet_filter_container">
        <CustomSelectMultipleDropdown
          lable={"Filter Based on Sole Traders"}
          options={soleTraders}
          onSelect={handleSelectSoleTraders}
          selectedValue={selectedSoleTraders}
          styles={{ flex: 1 }}
        />
        <CustomSelectMultipleDropdown
          lable={"Filter Based on Sites"}
          options={sites}
          onSelect={handleSelectSite}
          selectedValue={selectedSites}
          styles={{ flex: 1 }}
        />
      </div>

      <button className="reset_timesheet_filter_btn" onClick={resetFilters}>
        Reset Filters
      </button>

      <div>
        <div
          className="timesheet_details_cont"
          style={{ overflowX: "auto", maxWidth: "100%" }}
        >
          <Table className="timesheet_details timesheet_details_wraper" bordered style={{ width: "auto" }}>
            <thead>
              <tr>
                <th style={{ minWidth: '10rem' }}>Sites / Project</th>
                <th>Contractor Name</th>
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th>Sunday</th>
                <th>Comments</th>
                <th style={{ minWidth: '5rem' }}>Total Hours</th>
                <th style={{ minWidth: '5rem' }}>Hourly Rate</th>
                <th style={{ minWidth: '9rem' }}>Day Rate</th>
                <th>Total Pay</th>
                <th>Payment Amendments</th>
                <th>Reason for Amendments</th>
                <th>Adjusted Pay</th>
              </tr>
            </thead>
            <tbody>
              {/* SITE ROWS */}
              {
                timeSheetDetails.map(data => {
                  if (data.TimeSheets.length === 0) {
                    return;
                  }

                  return (
                    <>
                      <tr className="site_name" key={data.id}>
                        <td style={{ minWidth: '10rem' }}>{data.name}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {/*INDIVIDUAL TIMESHEETS */}
                      {
                        data.TimeSheets.length !== 0 && data.TimeSheets.map(tdata => {
                          const soeTraderName = `${tdata.SoleTrader.SoleTraderProfile.firstname + " " + tdata.SoleTrader.SoleTraderProfile.surname}`
                          const workUnit = tdata.SoleTrader.WorkUnit;
                          const totalHours = +tdata.monday_working_hours + +tdata.tuesday_working_hours + +tdata.wednesday_working_hours + +tdata.thursday_working_hours + +tdata.friday_working_hours + +tdata.saturday_working_hours + +tdata.sunday_working_hours;

                          let hourlyRate = +(workUnit === 'HOURS' ? (tdata.current_daily_pay_rate || 0) : tdata.current_daily_pay_rate / 9);

                          const comments = [
                            {
                              day: 'Monday',
                              comment: tdata.monday_comment
                            },
                            {
                              day: 'Tuesday',
                              comment: tdata.tuesday_comment
                            },
                            {
                              day: 'Wednesday',
                              comment: tdata.wednesday_comment
                            },
                            {
                              day: 'Thursday',
                              comment: tdata.thursday_comment
                            },
                            {
                              day: 'Friday',
                              comment: tdata.friday_comment
                            },
                            {
                              day: 'Saturday',
                              comment: tdata.saturday_comment
                            },
                            {
                              day: 'Sunday',
                              comment: tdata.sunday_comment
                            },
                          ];

                          const dayRate = workUnit === 'DAYS' ? tdata.current_daily_pay_rate : '-';
                          const totalPay = totalHours * hourlyRate;

                          const adjustedPay = totalPay + +tdata.manullay_deduction;

                          const isApproved = tdata.status === 'APPROVED';

                          const showCurrentComments = () => {
                            setShowComments(true);
                            setComments(comments)
                          }

                          return (
                            <tr key={tdata.id}>
                              <td style={{ minWidth: '10rem' }}>
                                <button className="approve_timesheet_btn" onClick={() => !isTImesheetApproved && approveSingletimesheet(tdata, isApproved)}>
                                  <img src={icons.Check} alt="approve" width={'10px'} />
                                  {isApproved ? 'APPROVED' : 'APPROVE TIMESHEET'}
                                </button>
                              </td>
                              <td>{soeTraderName}</td>
                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='number' name="monday_working_hours" value={tdata.monday_working_hours} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>
                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='number' name="tuesday_working_hours" value={tdata.tuesday_working_hours} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>
                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='number' name="wednesday_working_hours" value={tdata.wednesday_working_hours} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>
                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='number' name="thursday_working_hours" value={tdata.thursday_working_hours} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>
                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='number' name="friday_working_hours" value={tdata.friday_working_hours} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>
                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='number' name="saturday_working_hours" value={tdata.saturday_working_hours} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>
                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='number' name="sunday_working_hours" value={tdata.sunday_working_hours} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>
                              <td>
                                <button className="approve_timesheet_btn" onClick={showCurrentComments}>
                                  Show
                                </button>
                              </td>
                              <td>{isNaN(totalHours) ? totalHours : totalHours.toFixed(2)}</td>
                              <td>{`£${hourlyRate.toFixed(2)}`}</td>
                              <td>{workUnit === 'HOURS' ? '-' : dayRate}</td>
                              <td>£{totalPay.toFixed(2)}</td>

                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='number' name="manullay_deduction" value={tdata.manullay_deduction} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>

                              <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                                <input type='text' name="remark" value={tdata.remark} onChange={onChnageInput} data-site={data.id} data-timesheet={tdata.id} disabled={isApproved} />
                              </td>

                              <td>£{(+adjustedPay).toFixed(2)}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  )
                })
              }

              {/* Measured Works */}
              {
                measuredWorks.length !== 0 &&
                <>
                  <tr className="site_name">
                    <td style={{ minWidth: '10rem' }}>Measured Works Claims</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  {
                    measuredWorks.map(sdata => {
                      const isApproved = sdata.status === 'APPROVED';
                      const adjusted_amount = sdata.adjusted_amount || sdata.gross_amount;

                      return (
                        <tr key={sdata.id}>
                          <td style={{ minWidth: '10rem' }}>
                            <button className="approve_timesheet_btn" onClick={() => !isTImesheetApproved && approveSingletimesheet(sdata, isApproved)}>
                              <img src={icons.Check} alt="approve" width={'10px'} />
                              {isApproved ? 'APPROVED' : 'APPROVE TIMESHEET'}
                            </button>
                          </td>
                          <td>{sdata.SoleTrader.SoleTraderProfile.firstname} {sdata.SoleTrader.SoleTraderProfile.surname}</td>
                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>0.00</td>
                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>0.00</td>
                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>0.00</td>
                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>0.00</td>
                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>0.00</td>
                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>0.00</td>
                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>0.00</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td><button className="download_measure_btn" onClick={() => handleDownloadClaim(sdata.SoleTrader.id)}>
                            DOWNLOAD MEASURE
                          </button></td>
                          <td>
                            <input type='text' name="adjusted_amount" value={`£${sdata.adjusted_amount || sdata.gross_amount}`} onChange={(e) => onChnageInput(e, true)} data-site={sdata.site_id} data-timesheet={sdata.id} disabled={isApproved} />
                          </td>
                          {/* <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>0.00</td> */}

                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                            <input type='number' name="manullay_deduction" value={sdata.manullay_deduction} onChange={(e) => onChnageInput(e, true)} data-site={sdata.id} data-timesheet={sdata.id} disabled={isApproved} />
                          </td>

                          <td className="bordered" style={{ backgroundColor: isApproved ? '#EB742E' : 'white' }}>
                            <input type='text' name="remark" value={sdata.remark} onChange={(e) => onChnageInput(e, true)} data-site={sdata.id} data-timesheet={sdata.id} disabled={isApproved} />
                          </td>

                          <td>£{+adjusted_amount + +sdata.manullay_deduction}</td>
                        </tr>
                      )
                    })
                  }
                </>
              }

              {/* Manual Timesheets */}
              {
                manualTimesheets.length !== 0 &&
                <>
                  <tr className="site_name">
                    <td style={{ minWidth: '10rem' }}>Manual Timesheets</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  {
                    manualTimesheets.map(sdata => <ManualTimesheetRow data={sdata} getTimeSheetDetails={getTimeSheetDetails} onChangeManualTimesheet={onChangeManualTimesheet} />)
                  }
                </>
              }

              {/* Sole Traders With No Timesheets */}
              {
                soleTradersWithNoTimesheet.length !== 0 &&
                <>
                  <tr className="site_name">
                    <td style={{ minWidth: '10rem' }}>NO TIMESHEET</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  {
                    soleTradersWithNoTimesheet.map(sdata => <NoTimesheetRow data={sdata} isTImesheetApproved={isTImesheetApproved} extraData={{ workPeriod }} getTimeSheetDetails={getTimeSheetDetails} />)
                  }
                </>
              }


              {/* TOTAL */}
              <tr className="total_row">
                <td style={{ minWidth: '10rem' }}>Total</td>
                <td></td>
                <td>{totalData.mondayHours.toFixed(2)}</td>
                <td>{totalData.tuesdayHours.toFixed(2)}</td>
                <td>{totalData.wednesdayHours.toFixed(2)}</td>
                <td>{totalData.thursdayHours.toFixed(2)}</td>
                <td>{totalData.fridayHours.toFixed(2)}</td>
                <td>{totalData.saturdayHours.toFixed(2)}</td>
                <td>{totalData.sundayHours.toFixed(2)}</td>
                <td></td>
                <td>{totalData.totalHours.toFixed(2)}</td>
                <td>£{totalData.hourlyRate.toFixed(2)}</td>
                <td>£{totalData.dayRate.toFixed(2)}</td>
                <td>£{totalData.totalPay.toFixed(2)}</td>
                <td>£{totalData.manullay_deduction.toFixed(2)}</td>
                <td></td>
                <td>£{totalData.adjustedPay.toFixed(2)}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div className="master_timesheet_footer">
          <ul>
            <p>NOTES</p>
            <li>The timesheet needs to be submitted to Subby Group by 9 am Wednesday.</li>
            <li>Once submitted, no changes can be made.</li>
            <li>Once received, an invoice will be sent to you for processing.</li>
            <li>Payment of your invoice needs to be received by 9 am Thursday.</li>
            <li>As long as we receive your approved timesheet by 9 am Wednesday and subsequent payment of our invoice by 9 am Thursday, we will process subcontractor payments to reach them on Friday.</li>
          </ul>

          <div className="calculations">
            <div>
              <p style={{ fontWeight: '600' }}>Number of Contractors</p>
              <p>{numberOfSoleTraders}</p>
              <p></p>
            </div>
            <div style={{ borderBottom: '1px solid #EB742E' }}>
              <p style={{ fontWeight: '600' }}>SubbyGroup Admin Charge Per Person @</p>
              <p>£{adminCharge}</p>
              <p>£{(numberOfSoleTraders * adminCharge).toFixed(2)}</p>
            </div>
            <div style={{ marginTop: '0.8rem' }}>
              <p style={{ fontWeight: '600' }}>Total to be invoiced by SubbyGroup</p>
              <p></p>
              <p>£{((numberOfSoleTraders * adminCharge) + +totalData.adjustedPay).toFixed(2)}</p>
            </div>
          </div>
        </div>

        {isTImesheetsSubmitted && <button className="approve_timesheet_btn" onClick={approveMasterTimeSheetHandler}>
          <img src={icons.Check} alt="approve" width={'20px'} />
          {isTImesheetApproved ? 'APPROVED' : 'APPROVE TIMESHEET'}
        </button>}

      </div>
    </>
  );
};

export default TimeSheetDetail;
