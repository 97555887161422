import axios from "axios";

export const getDocumentationApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-site-documents?page=${params?.page}${params?.limit ? (`&limit=${params?.limit}`) : ''}${params?.name ? (`&name=${params.name}`) : ''}${params?.sortBy ? (`&sortBy=${params?.sortBy}&sort=${params.sortType}`) : ''}`
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      resolve(response.data)
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data)
    }
  })
}

// Edit Documents //
export const getSingleDocumentApi = async (userToken, documentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        headers: {
          Authorization: userToken
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-site-document/${documentId}`,
      })

      resolve(response.data)
    } catch (error) {
      console.log("error::", error.response.data);
      reject(error.response.data)
    }
  })
}

// Add Documents //
export const addDocumentApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/add-site-documents`,
        data: params
      })

      resolve(response.data)
    } catch (error) {
      console.log("Add SDocument Page error::", error);
      reject(error.response.data)
    }
  })
}

export const updateDocumentApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/update-site-document`,
        data: params
      })

      resolve(response.data)
    } catch (error) {
      console.log("error::", error.response.data);
      reject(error.response.data)
    }
  })
}

export const addInductionApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "POST",
        headers: {
          Authorization: userToken,
          'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/upload-induction-video`,
        data: params
      })

      resolve(response.data)
    } catch (error) {
      console.log("error::", error.response.data);
      reject(error.response.data)
    }
  })
}

export const deleteDocumentApi = async (userToken, params) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/delete-site-document/${params}`
    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      resolve(response.data)
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data)
    }
  })
}

export const deleteInductionVideoApi = async (userToken) => {
  return new Promise(async (resolve, reject) => {
    const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/delete-induction-video`
    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: userToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      resolve(response.data)
    } catch (error) {
      console.log("api error::", error.response?.data);
      reject(error.response?.data)
    }
  })
}