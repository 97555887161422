import React, { useState } from "react";

// Style //
import "./AddSubContractor.css";
import Form from "react-bootstrap/Form";

// Images //
import { images } from "../../assets/index";

// Component //
import {
  FormInput,
  PageHeading,
  ButtonComp,
  Loader,
} from "../../common/components/index";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// API's //
import { addSoleTraderApi } from "../../api/soleTraderApi";

// Actions //
import {
  receivedError,
  responseRecived,
  requestSent,
} from "../../redux/actions/utilsActions";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddSubContractor = () => {
  // Hooks //

  const [formData, setFormData] = useState({
    firstname: "",
    surname: "",
    email: "",
    phone_number: "",
    daily_pay_rate: "",
    daily_pay_rate_currency: "£",
    WorkUnit: "",
  });
  const [respMessage, setRespMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.utils);
  const { userToken } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  // Handler //
  const addSubContractorHendler = async () => {
    try {
      if (
        !formData.firstname ||
        !formData.surname ||
        !formData.email ||
        !formData.phone_number ||
        !formData.daily_pay_rate ||
        !formData.WorkUnit
      ) {
        alert("!!Please fill all the fields!!");
        return;
      }
      setError("");
      dispatch(requestSent());
      const data = await addSoleTraderApi(userToken, formData);
      setRespMessage(data.message);
      dispatch(responseRecived());
      setModalShow(true);
    } catch (error) {
      console.log("Add Sub Contarctor Page error::", error);
      dispatch(receivedError(error));
      setError(error);
    }
  }

  const modalHendler = () => {
    setModalShow(false);
    navigate("/sub-contractors");
  }

  // Modal //
  const CustomModal = () => {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <img src={images.success} alt="" />
            <h2>Sole-Trader Added</h2>
            <p>{respMessage}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="orange_btn btn_style" onClick={modalHendler}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <CustomModal />
      <PageHeading title={"Add A New Sub-Contractor"} />
      <div id="add-subcontractor" className="right-sec_cont">
        <div>
          <h2>Sub-Contractor Details</h2>
          <div className="first_last_name_inp">
            <FormInput
              width={"49%"}
              lable="First Name"
              placeholder={"Enter First Name"}
              onchange={(value) =>
                setFormData({
                  ...formData,
                  firstname: value,
                })
              }
              value={formData.firstname}
              type={"text"}
              error={error?.error_key === "firstname" ? true : false}
            />
            <FormInput
              width={"49%"}
              lable="Last Name"
              placeholder={"Enter Last Name"}
              onchange={(value) =>
                setFormData({
                  ...formData,
                  surname: value,
                })
              }
              value={formData.surname}
              type={"text"}
              error={error?.error_key === "surname" ? true : false}
            />
          </div>
          <FormInput
            width={"30vw"}
            lable="Email"
            placeholder={"Enter Email"}
            onchange={(value) =>
              setFormData({
                ...formData,
                email: value,
              })
            }
            value={formData.email}
            type={"text"}
            error={error?.error_key === "email" ? true : false}
          />

          <FormInput
            width={"30vw"}
            lable="Mobile Number"
            placeholder={"Enter Mobile No."}
            onchange={(value) => {
              setFormData({
                ...formData,
                phone_number: value,
              });
            }}
            value={formData?.phone_number}
            type={"number"}
            error={error?.error_key === "phone_number" ? true : false}
          />
          <FormInput
            width={"30vw"}
            lable="Rate Of Pay"
            placeholder={"Enter Pay Rate"}
            onchange={(value) =>
              setFormData({
                ...formData,
                daily_pay_rate: value,
              })
            }
            value={formData.daily_pay_rate}
            type={"number"}
            error={error?.error_key === "daily_pay_rate" ? true : false}
          />
          <div className="select_inp form_input_cont">
            <p>Work Unit</p>
            <Form.Select
              className="input"
              onChange={(event) => {
                const { value } = event.target;
                setFormData({
                  ...formData,
                  WorkUnit: value === 'measure' ? 'AREA' : value.toUpperCase(),
                });
              }}
              value={formData?.WorkUnit}
            >
              <option>Select</option>
              <option value={"HOURS"}>HOURS</option>
              <option value={"DAYS"}>DAYS</option>
              <option value={"AREA"}>MEASURE</option>
            </Form.Select>
          </div>

          {error ? <p className="err_message">*{error?.error_message}</p> : ""}
          <ButtonComp
            onClick={addSubContractorHendler}
            title={"Add Sub-Contractor"}
            width={"30vw"}
          />
        </div>
      </div>
    </>
  );
};

export default AddSubContractor;
